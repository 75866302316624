import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 30 min. `}<meta itemProp="prepTime" content="PT30M" /></h5>
    <h5>{`Cook time: 30 min. `}<meta itemProp="cookTime" content="PT30M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`3-4`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Chickpeas, 1 15 oz. can</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Lemon, 1</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Tahini (well-stirred), 1/4 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 1 clove minced</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Olive oil, 2 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cumin, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cayenne, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Water, 2-3 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Smoked paprika, for garnish</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Food processor</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Colander</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Lemon squeezer</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Spatula (optional)</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Small bowl</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Slice lemon into halves</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Mince garlic</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Drain and rinse chickpeas</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Add tahini and squeeze lemon juice into the bowl of the food processor. Process for 1 minute.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Scrape sides of the food processor and then process for 30 seconds more.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add garlic, olive oil, cumin, cayenne, and salt to the food processor and process for 30 seconds.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Scrape sides of the food processor and then process for 30 seconds more.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add half of the chickpeas to the food processor and process for 1 minute.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Scrape sides of the food processor, add remaining chickpeas, and then process for 1 minute more.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add 2-3 tbs. water until the consistency looks creamy.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Move hummus to small bowl for serving. Top with smoked paprika.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      